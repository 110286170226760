<template>
  <v-container fluid class="px-1">
    <v-row class="box-letter" v-if="load_promotions">
      <v-col cols="12" sm="6" md="6" lg="6" class="pt-1" v-for="n in 4" :key="n">    
        <v-sheet class="">
          <v-responsive class="mx-auto">
            <v-skeleton-loader
              ref="skeleton"
              type="image"
              height="130"
              class="mx-auto"
            ></v-skeleton-loader>
          </v-responsive>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row no-gutters class="box-letter">
      <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '6'" :class="$vuetify.breakpoint.mdAndUp ? 'pr-6' : 'px-4'" v-for="(item, i) in promotions" :key="i">
        <p class="title_promotion landing_primary--text">{{ item.name | traslate($store.getters.language) }}</p>
        <v-card class="mt-0 card-letter" flat>
          <v-img class="" :src="url_img+'images/promotions/'+item.image_promotion" :lazy-src="url_img+'images/promotions/'+item.image_promotion" aspect-ratio="1.4" :max-height="imgPromotion">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
          </template>
          </v-img>
        </v-card>       
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  export default {
    name: 'Promotions',
    data: () => ({ 
      promotions: [],  
      load_promotions: false,
      url_img: process.env.VUE_APP_IMG_URL_API,
    }),
    created(){
      this.getPromotions();
    },
    methods:{
      async getPromotions(){
        const url = this.$route.params.landing ? this.$route.params.landing : '';
        this.load_promotions = true;
        try{
          const {data} = await this.$api.get(`api/restaurant/${url}/promotions`);
        
          this.promotions = data.data.restaurant.promotions;          
        }  
        catch(e){
          if(e.response.data.error_code == '400'){
            this.$router.push({name: '404', query: {msj: e.response.data.message}})
          }
          else{
            this.$router.push({name: '404'})
          }
        }
        finally {
          this.load_promotions = false;
        }
      }
    },
    computed: {
      imgMenu(){
        const height = this.$vuetify.breakpoint.mdAndUp ? '50vh' : '45vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      name_menu(){
        if(this.detailsMenu.name)
          return this.detailsMenu.name[this.$store.getters.language] ? this.detailsMenu.name[this.$store.getters.language] : this.detailsMenu.name.es;
        return '';
      },
      imgPromotion(){
        const height = this.$vuetify.breakpoint.mdAndUp ? '40vh' : '30vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    filters:{
      traslate(val, lang){
        return val[lang] ? val[lang] : val.es; 
      }
    }
  }
</script>
<style lang="sass">
  .title_promotion
    font-size: 1rem
    font-weight: 500
    line-height: 1.5rem
    padding-top: 12px
    padding-bottom: 12px
    margin: 0 !important
    letter-spacing: 1px


</style>